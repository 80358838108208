<template>
    <div class="row-user">
        <div class="column" v-for="user in users" :key="user.id">
            <div 
                class="user-box"
                @click="onSearchUser(user)"
            >
                {{ user.id }}
                <p>{{ user.name }}</p>
                <div class="line" :class="'user-line-'+line" @load="prueba($event)"></div>
            </div>
            <nested-component
                :users="user.sponsored"
                :line="user.id"
                @onSearchUser="onSearchUser($event)"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'nested-component',
    props: {
        line: {},
        users: {},
    },
    methods: {
        onSearchUser(oUser) {
            this.$emit('onSearchUser', oUser)
        },
        onDrawLines(){
            let lines = document.querySelectorAll('.user-line-'+this.line);
            if(lines.length > 0){
                let { p1, p2 } = 0;
                p1 = this.getCoords(lines[0]).left;
                if(lines[1]) p2 = this.getCoords(lines[1]).left;
                let width = p2 - p1; 

                let line = lines[0];
                let hLine = document.createElement('div');
                hLine.setAttribute('style', `width: ${width}px`);
                hLine.setAttribute('class', 'h-line');
                line.appendChild(hLine);
            }
        },
        getCoords(elem) {
            let box = elem.getBoundingClientRect();
            return {
                top: box.top + window.pageYOffset,
                right: box.right + window.pageXOffset,
                bottom: box.bottom + window.pageYOffset,
                left: box.left + window.pageXOffset
            };
        }
    },
    mounted(){
        this.onDrawLines();
    }
}
</script>
<style>
.column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.row-user {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
.h-line {
    height: .3rem;
    background: #5a5a5a;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    z-index: -10;
}
</style>