<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Navegación de patrocinadores</h1>
            <div class="info-card">
                <h3>Información</h3>
                <hr>
                <p>Navega y conoce el trabajo que han realizado tus patrocinados para así asegurar un mejor trabajo en equipo.</p>
            </div>
            <div 
                class="main-user-content"
            >
                <span 
                    class="material-icons-sharp return-arrow" 
                    @click="backNavigation()"
                    v-if="oMainUser.id !== oInitialUser.id"
                >expand_less</span>
                <div 
                    class="user-box main-user"
                >
                    {{ oMainUser.id }}
                    <p>{{ oMainUser.name }}</p>
                    
                </div>
            </div>
            <div class="contenedor-nest">
                <Nested 
                    :line="nLine"
                    :users="aUsersMLM"
                    @onSearchUser="onSearchUser($event)"
                />
            </div>
        </main>
        <Right/>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import Nested from '../components/Nested.vue'
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';
import axios from 'axios';

export default {
    name: 'Multinivel',
    components: {
        Aside,
        Right,
        Nested
    },
    data: function(){
        return {
            oInitialUser: {},
            oMainUser: {},
            nSearchId: 0,
            nLine: 0,
            loading_commissions: false,
            aNavigation: [],
            aUsersMLM: []
        }
    },
    methods:{
        onSearchUser({id, name}){
            this.aNavigation.push({id, name});
            this.oMainUser = {id, name};
            this.getUserMultinivel(id);
        },
        backNavigation(){
            const index = this.aNavigation.findIndex(e => e.id === this.oMainUser.id);
            if(index-1 < 0) this.oMainUser = this.oInitialUser;
            else this.oMainUser = this.aNavigation[index-1];
            this.aNavigation = this.aNavigation.slice(0, index-1)
            this.onSearchUser(this.oMainUser);
        },
        getUserMultinivel(id) {
            let url = this.api_url+'/api/users/'+id+'/sponsors/ordered';
            axios.get(url,
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => this.aUsersMLM = res.data.data)
            .catch(err => {
                console.log(err.response)
            })
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            if(mes < 10)  mes = "0" + mes
            if(dia < 10) dia = "0" + dia
            return ano+"/"+mes+"/"+dia;
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapMutations(['loadSessionUser']),
    },
    mounted() {
        $('.modal-backdrop').remove();
        this.loadSessionUser();
    },
    computed: {
        ...mapState(['api_url']),
        ...mapState(['session_user']),
    },
    mounted(){
        let session_interval = setInterval(() => {
            if(this.session_user.id) {
                this.oInitialUser = { id: this.session_user.id, name: this.session_user.name };
                this.oMainUser = { id: this.session_user.id, name: this.session_user.name }
                this.aNavigation.push({...this.oInitialUser})
                this.getUserMultinivel(this.oInitialUser.id);
                return clearInterval(session_interval)}
        }, 100);
    },
}
</script>
<style>
.link{color: #007bff;}
.return-arrow {
    cursor: pointer;
    margin: .5rem 0;
}
.user-box {
    max-width: 150px;
    background: #fff;
    padding: .5rem 1rem;
    border-radius: .2rem;
    box-shadow: 0 0 2px #5a5a5a;
    cursor: pointer;
    position: relative;
}
.main-user-content {
    margin-top: 1rem;
    display: flex; 
    flex-direction: column; 
    align-items: center;
}
.main-user {
    background: #e7e7e7;
    margin: 0 auto;
    min-width: 120px;
}
.user-box p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.contenedor-nest {
    padding: 1rem .5rem;
    margin: 0 auto;
    overflow-x: scroll;
    box-sizing: border-box;
    max-width: calc(100vw - 45rem) 
}
.line {
    width: .3rem;
    height: 5rem;
    background: #5a5a5a;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2rem;
    margin: auto;
    z-index: -10;
}
@media screen and (max-width: 1200px) {
    .contenedor-nest { max-width: calc(100vw - 32rem) }
}

@media screen and (max-width: 838px) {
    .contenedor-nest { max-width: calc(95vw) }
}
</style>